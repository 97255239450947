import React from "react";
import { Badge, Card, Ratio } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { convertMetresToMiles } from "../../utils/locations";

const BuddyCard = ({
  imgSrc,
  srcSet,
  locationString,
  distanceFrom,
  name,
  type,
  uid,
  profileBadges,
  verified,
  lastSeen,
}) => {
  const lastActive = lastSeen ? `Last seen ${lastSeen}` : "";

  return (
    <>
      <Card className="position-relative h-100">
        <Ratio aspectRatio="1x1">
          {!!imgSrc && (
            <Card.Img
              src={imgSrc}
              className="h-100"
              srcSet={`${srcSet[0]} 1x, ${srcSet[1]} 2x`}
              variant="top"
              width="256"
              height="256"
              style={{ objectFit: "cover" }}
            />
          )}
        </Ratio>

        <div className="position-absolute top-0 end-0 start-0 m-2 d-flex flex-row gap-2 align-items-center justify-content-end flex-wrap">
          {type === "INSTRUCTOR" && (
            <Badge
              bg="dark"
              text="white"
              className="px-2 fw-bold d-flex align-items-center gap-1"
            >
              <span className="material-symbols-sharp fs-6">verified</span>{" "}
              Instructor
            </Badge>
          )}
          {verified && (
            <Badge
              bg="dark"
              text="white"
              className="px-2 fw-bold d-flex align-items-center gap-1"
            >
              <span className="material-symbols-sharp fs-6">verified_user</span>{" "}
              DBS Checked
            </Badge>
          )}
        </div>

        <Card.Body className="p-2 p-sm-3 pb-0 pb-sm-0">
          <Card.Title as="h4" className="m-0">
            <Link
              className="text-black stretched-link"
              to={`/find-buddies/${uid}`}
            >
              <span className="d-block text-truncate">{name}</span>
            </Link>
          </Card.Title>

          {profileBadges && (
            <div className="pt-2 d-flex flex-column flex-sm-row gap-3 flex-wrap mt-1 align-items-start align-items-sm-center">
              {profileBadges.map(function (badge, index) {
                return (
                  <div
                    className="d-inline-flex align-items-center gap-1 small"
                    key={badge.name}
                  >
                    <span className="material-symbols-sharp">{badge.icon}</span>{" "}
                    {badge.name}
                  </div>
                );
              })}
            </div>
          )}
        </Card.Body>
        <Card.Footer className="small d-flex flex-column justify-content-between p-2 p-sm-3 border-top">
          <div>{distanceFrom}</div>
          <div>{lastActive}</div>
        </Card.Footer>
      </Card>
    </>
  );
};

BuddyCard.defaultProps = {
  imgSrc: "/lcc-user-profile-default.svg",
  locationString: "Hackney",
};

BuddyCard.propTypes = {
  imgSrc: PropTypes.string,
  srcSet: PropTypes.array,
  locationString: PropTypes.string.isRequired,
  distanceFrom: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["NEW_RETURNING", "EXPERIENCED", "INSTRUCTOR"])
    .isRequired,
  uid: PropTypes.string.isRequired,
  badges: PropTypes.array,
};

export default BuddyCard;
