import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Alert, Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import img1 from "../../assets/images/lcc-user-profile-default.svg";
import { Controller } from "react-hook-form";
import SettingsTab from "./SettingsTab";
import FormFieldErrorMessage from "../common/FormFieldErrorMessage";
import { Link } from "react-router-dom";
import { postCodeRegex } from "../../utils/locations";

const SettingsProfile = ({
  control,
  errors,
  onSubmit,
  register,
  resetField,
  title,
  user,
  watch,
  getValues,
}) => {
  const [editPhotoMode, setEditProfileMode] = useState(false);
  const watchPhoto = watch("profile_photo");

  useEffect(() => {
    if (typeof watchPhoto === "object") {
      setEditProfileMode(true);
    } else {
      setEditProfileMode(false);
    }
  }, [watchPhoto]);

  useEffect(() => {
    setEditProfileMode(false);
  }, [user?.profile_photo_med]);

  const [isValid, setIsValid] = useState(false);

  const watchFields = watch([
    "home_postcode",
    "work_postcode",
    "first_name",
    "last_name",
    "username",
  ]);

  useEffect(() => {
    const homePostCode = getValues("home_postcode");
    const workPostCode = getValues("work_postcode");

    const firstName = getValues("first_name");
    const lastName = getValues("last_name");
    const username = getValues("username");

    // missing fields
    if (!firstName || !lastName || !username) {
      setIsValid(false);
      return;
    }

    // No postcodes are set
    if (homePostCode === "" && workPostCode === "") {
      setIsValid(false);
      return;
    }

    const homePostCodeValid = !homePostCode || postCodeRegex.test(homePostCode);
    const workPostCodeValid = !workPostCode || postCodeRegex.test(workPostCode);

    // Postcodes aren't valid
    if (!homePostCodeValid || !workPostCodeValid) {
      setIsValid(false);
      return;
    }

    setIsValid(true);
  }, [watchFields]);

  return (
    <SettingsTab title="Profile">
      <form onSubmit={onSubmit} noValidate={true}>
        <input {...register("non_field_errors")} type="hidden" />
        <Form.Group className="form-group align-items-center">
          <Col md="12">
            <div className="profile-img-edit">
              {editPhotoMode ? (
                <>
                  <div className="profile-pic" style={{}}>
                    Upload new file
                  </div>
                </>
              ) : (
                <>
                  <img
                    className="profile-pic"
                    src={
                      !!user?.profile_photo_med ? user?.profile_photo_med : img1
                    }
                    alt="profile-pic"
                  />
                </>
              )}

              <label className="p-image d-flex justify-content-center align-items-center">
                {editPhotoMode ? (
                  <>
                    <i
                      className="material-symbols-sharp upload-button text-white"
                      onClick={(e) => {
                        e.preventDefault();
                        resetField("profile_photo");
                      }}
                    >
                      close
                    </i>
                  </>
                ) : (
                  <>
                    <i className="material-symbols-sharp upload-button text-white">
                      edit
                    </i>
                  </>
                )}

                <input
                  className="file-upload"
                  type="file"
                  accept="image/*"
                  {...register("profile_photo")}
                />
              </label>
            </div>
          </Col>
        </Form.Group>
        <div className="pb-3">
          <Row className="align-items-center">
            <Form.Group className="form-group col-sm-6">
              <Form.Label>First name</Form.Label>
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder="First Name"
                  />
                )}
              />
              <FormFieldErrorMessage fieldError={errors?.first_name} />
            </Form.Group>
            <Form.Group className="form-group col-sm-6">
              <Form.Label>Last name</Form.Label>
              <Controller
                name="last_name"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder="Last Name"
                  />
                )}
              />
              <FormFieldErrorMessage fieldError={errors?.last_name} />
            </Form.Group>
          </Row>
          <Form.Group className="form-group">
            <Form.Label>Screen name</Form.Label>
            <Controller
              name="username"
              control={control}
              render={({ field }) => {
                return (
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder="Screen name"
                  />
                );
              }}
            />
            <FormFieldErrorMessage fieldError={errors?.username} />
          </Form.Group>
        </div>

        <Form.Group className="form-group col-sm-12 pb-3">
          <Form.Label className="form-label">Biography</Form.Label>
          <Controller
            name="introduction"
            control={control}
            render={({ field }) => (
              <Form.Control
                as="textarea"
                {...field}
                rows={5}
                style={{ lineHeight: "1.375" }}
                type="text"
                placeholder="Please add something about yourself."
              />
            )}
          />
          <FormFieldErrorMessage fieldError={errors?.introduction} />
        </Form.Group>

        <Form.Group className="form-group">
          <Controller
            name="profile_is_public"
            control={control}
            render={({ field }) => (
              <Form.Check
                {...field}
                label="Make my profile public"
                type="checkbox"
                checked={field.value}
              />
            )}
          />
          <FormFieldErrorMessage fieldError={errors?.profile_is_public} />
          <Form.Text muted>
            This makes your profile publicly viewable to people without LCC
            accounts, so you can share it via external channels. <br />
            <Link to={`/find-buddies/${user.uid}`}>
              View my public profile.
            </Link>
          </Form.Text>
        </Form.Group>
        <Form.Group className="form-group">
          <Controller
            name="pause_profile"
            control={control}
            render={({ field }) => (
              <Form.Check
                {...field}
                label="Take a break"
                type="checkbox"
                checked={field.value}
              />
            )}
          />
          <FormFieldErrorMessage fieldError={errors?.pause_profile} />

          <Form.Text muted>
            This pauses your profile so new Cycle Buddies wont be able to start
            conversations with you. This doesn't affect any of your existing
            conversations.
          </Form.Text>
        </Form.Group>

        <fieldset className="form-group my-5 pb-3" id="location">
          <legend className="h3 mb-3">Locations</legend>

          {/* NEW OR RETURNING */}

          {user?.rider_type === "NEW_RETURNING" && (
            <>
              <p>
                <b>I would like help near:</b>
              </p>

              <Row className="pb-3">
                <Form.Group className=" col-sm-6 mb-1">
                  <Form.Label>My home address</Form.Label>

                  <Controller
                    name="home_postcode"
                    control={control}
                    // rules={{
                    //   pattern : /^(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) [0-9][A-Z]{2}$/
                    // }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="Postcode"
                        className="w-auto"
                      />
                    )}
                  />

                  <FormFieldErrorMessage
                    fieldError={errors?.home_postcode}
                    customBlankMessage="Please enter a valid postcode"
                  />
                </Form.Group>
                <Form.Group className="form-group col-sm-6 mb-1">
                  <Form.Label>My work address</Form.Label>

                  <Controller
                    name="work_postcode"
                    control={control}
                    // rules={{
                    //   pattern : /^(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) [0-9][A-Z]{2}$/
                    // }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="Postcode"
                        className="w-auto"
                      />
                    )}
                  />
                  <FormFieldErrorMessage
                    fieldError={errors?.work_postcode}
                    customBlankMessage="Please enter a valid postcode"
                  />
                </Form.Group>
              </Row>
            </>
          )}

          {/* EXPERIENCED */}
          {user?.rider_type === "EXPERIENCED" && (
            <>
              <p>
                <b>I would like to help people near:</b>
              </p>

              <Row className="pb-3">
                <Form.Group className=" col-sm-6 mb-1">
                  <Form.Label>My home address</Form.Label>

                  <Controller
                    name="home_postcode"
                    control={control}
                    // rules={{
                    //   pattern : /^(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) [0-9][A-Z]{2}$/
                    // }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="Postcode"
                        className="w-auto"
                      />
                    )}
                  />

                  <FormFieldErrorMessage
                    fieldError={errors?.home_postcode}
                    customBlankMessage="Please enter a valid postcode"
                  />
                </Form.Group>
                <Form.Group className="form-group col-sm-6 mb-1">
                  <Form.Label>My work address</Form.Label>

                  <Controller
                    name="work_postcode"
                    control={control}
                    // rules={{
                    //   pattern : /^(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) [0-9][A-Z]{2}$/
                    // }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="Postcode"
                        className="w-auto"
                      />
                    )}
                  />

                  <FormFieldErrorMessage
                    fieldError={errors?.work_postcode}
                    customBlankMessage="Please enter a valid postcode"
                  />
                </Form.Group>
              </Row>
            </>
          )}

          {user?.rider_type === "INSTRUCTOR" && (
            <>
              <p>
                <b>I would like to help people near:</b>
              </p>
              <Row className="pb-3">
                <Form.Group className=" col-sm-6 mb-1">
                  <Form.Label>My home address</Form.Label>
                  <InputGroup className="mb-1 flex-nowrap">
                    <Controller
                      name="home_postcode"
                      control={control}
                      // rules={{
                      //   pattern : /^(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) [0-9][A-Z]{2}$/
                      // }}
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          type="text"
                          placeholder="Postcode"
                          className="w-auto"
                        />
                      )}
                    />
                    <Form.Select
                      {...register("number_of_miles_home")}
                      aria-label="Distance from address"
                      align="end"
                      className="w-auto"
                      required
                    >
                      {[...Array(10)].map((e, i) => {
                        return (
                          <option value={i + 1}>
                            + {i + 1} {i === 0 ? `mile` : `miles`}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </InputGroup>
                  <FormFieldErrorMessage
                    fieldError={errors?.home_postcode}
                    customBlankMessage="Please enter a valid postcode"
                  />
                </Form.Group>
                <Form.Group className="form-group col-sm-6 mb-1">
                  <Form.Label>My work address</Form.Label>
                  <InputGroup className="mb-1 flex-nowrap">
                    <Controller
                      name="work_postcode"
                      control={control}
                      // rules={{
                      //   pattern : /^(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) [0-9][A-Z]{2}$/
                      // }}
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          type="text"
                          placeholder="Postcode"
                          className="w-auto"
                        />
                      )}
                    />

                    <Form.Select
                      {...register("number_of_miles_work")}
                      aria-label="Distance from address"
                      align="end"
                      className="w-auto"
                      required
                    >
                      {[...Array(10)].map((e, i) => {
                        return (
                          <option value={i + 1}>
                            + {i + 1} {i === 0 ? `mile` : `miles`}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </InputGroup>

                  <FormFieldErrorMessage
                    fieldError={errors?.work_postcode}
                    customBlankMessage="Please enter a valid postcode"
                  />
                </Form.Group>
              </Row>
            </>
          )}

          <Form.Text muted>
            Only one location required, but you can add both if you wish.
            <br /> Your location will not be shared, only your approximate
            distance to other users.
          </Form.Text>
        </fieldset>

        {user?.rider_type === "NEW_RETURNING" && (
          <>
            <fieldset className="form-group mt-5">
              <legend className="h3 mb-3">Goal</legend>
              <Form.Check className="d-flex align-items-baseline">
                <Form.Check.Input
                  {...register("goals")}
                  type="radio"
                  name="goals"
                  id="COMPLETE_BEGINNER"
                  value="COMPLETE_BEGINNER"
                  className="flex-shrink-0"
                  defaultChecked={
                    user?.goals && user?.goals.includes("COMPLETE_BEGINNER")
                  }
                />
                <Form.Check.Label
                  className="d-inline-block my-1 ps-2"
                  htmlFor="COMPLETE_BEGINNER"
                >
                  I am a complete beginner and would like learn to ride
                  <small className="text-muted d-block">
                    This will match you with instructors. Sessions with
                    instructors are either free from your local council or can
                    be paid privately.
                  </small>
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="d-flex align-items-baseline">
                <Form.Check.Input
                  {...register("goals")}
                  type="radio"
                  name="goals"
                  id="QUIET_STREETS"
                  value="QUIET_STREETS"
                  className="flex-shrink-0"
                  defaultChecked={
                    user?.goals && user?.goals.includes("QUIET_STREETS")
                  }
                />
                <Form.Check.Label
                  className="d-inline-block my-1 ps-2"
                  htmlFor="QUIET_STREETS"
                >
                  I already ride in parks but I would like to have the
                  confidence to ride on very quiet streets in my neighbourhood
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="d-flex align-items-baseline">
                <Form.Check.Input
                  {...register("goals")}
                  type="radio"
                  name="goals"
                  id="LOCAL_ROUTES"
                  value="LOCAL_ROUTES"
                  className="flex-shrink-0"
                  defaultChecked={
                    user?.goals && user?.goals.includes("LOCAL_ROUTES")
                  }
                />
                <Form.Check.Label
                  className="d-inline-block my-2 ps-2"
                  htmlFor="LOCAL_ROUTES"
                >
                  I can already ride but I would like to be confident riding to
                  local shops and places of interest and learn some good local
                  routes in my neighbourhood
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="d-flex align-items-baseline">
                <Form.Check.Input
                  {...register("goals")}
                  type="radio"
                  name="goals"
                  id="LONGER_FITNESS"
                  value="LONGER_FITNESS"
                  className="flex-shrink-0"
                  defaultChecked={
                    user?.goals && user?.goals.includes("LONGER_FITNESS")
                  }
                />
                <Form.Check.Label
                  className="d-inline-block my-2 ps-2"
                  htmlFor="LONGER_FITNESS"
                >
                  I would like to be confident to go on longer rides out of my
                  neighbourhood for work, travel, fitness or leisure
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="d-flex align-items-baseline">
                <Form.Check.Input
                  {...register("goals")}
                  type="radio"
                  name="goals"
                  id="PARTICULAR_ROUTE"
                  value="PARTICULAR_ROUTE"
                  className="flex-shrink-0"
                  defaultChecked={
                    user?.goals && user?.goals.includes("PARTICULAR_ROUTE")
                  }
                />
                <Form.Check.Label
                  className="d-inline-block my-2 ps-2"
                  htmlFor="PARTICULAR_ROUTE"
                >
                  I ride regularly but would like help planning and riding a
                  particular route
                  <small className="text-muted d-block">
                    This will match you with volunteers and instructors.
                  </small>
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="d-flex align-items-baseline">
                <Form.Check.Input
                  {...register("goals")}
                  type="radio"
                  name="goals"
                  id="LEVEL_2_OR_3"
                  value="LEVEL_2_OR_3"
                  className="flex-shrink-0"
                  defaultChecked={
                    user?.goals && user?.goals.includes("LEVEL_2_OR_3")
                  }
                />
                <Form.Check.Label
                  className="d-inline-block my-2 ps-2"
                  htmlFor="LEVEL_2_OR_3"
                >
                  I would like to learn{" "}
                  <a
                    href="https://www.bikeability.org.uk/get-cycling/cycle-training-for-children/bikeability-level-2/"
                    className="text-nowrap"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Bikeability Level 2{" "}
                    <span className="material-symbols-outlined align-middle small">
                      open_in_new
                    </span>
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.bikeability.org.uk/get-cycling/cycle-training-for-children/bikeability-level-2/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-nowrap"
                  >
                    Level 3{" "}
                    <span className="material-symbols-outlined align-middle small">
                      open_in_new
                    </span>
                  </a>
                  <small className="text-muted d-block">
                    This will match you with instructors. Sessions with
                    instructors are either free from your local council or can
                    be paid privately.
                  </small>
                </Form.Check.Label>
              </Form.Check>
            </fieldset>
          </>
        )}

        {/* EXPERIENCED */}
        {user?.rider_type === "EXPERIENCED" && (
          <>
            <fieldset className="form-group">
              <legend className="h3">Goal</legend>
              <Row>
                <Col md={12}>
                  <fieldset className="form-group">
                    <p>
                      Please let us know the types of journey you want to help
                      new riders with (select as many as you like):
                    </p>
                    <Form.Check className="d-flex align-items-baseline">
                      <input
                        {...register("goals")}
                        className="form-check-input"
                        type="checkbox"
                        id="QUIET_STREETS"
                        value="QUIET_STREETS"
                      />
                      <Form.Check.Label
                        className="d-inline-block my-2 ps-2"
                        htmlFor="QUIET_STREETS"
                      >
                        to have the confidence to ride on very quiet streets in
                        the local neighbourhood
                      </Form.Check.Label>
                    </Form.Check>

                    <Form.Check className="d-flex align-items-baseline">
                      <Form.Check.Input
                        {...register("goals")}
                        type="checkbox"
                        id="LOCAL_ROUTES"
                        value="LOCAL_ROUTES"
                        required
                      />
                      <Form.Check.Label
                        className="d-inline-block my-2 ps-2"
                        htmlFor="LOCAL_ROUTES"
                      >
                        to be confident riding to local shops and places of
                        interest and learn some good local routes
                      </Form.Check.Label>
                    </Form.Check>

                    <Form.Check className="d-flex align-items-baseline">
                      <Form.Check.Input
                        {...register("goals")}
                        type="checkbox"
                        id="LONGER_FITNESS"
                        value="LONGER_FITNESS"
                        required
                      />
                      <Form.Check.Label
                        className="d-inline-block my-2 ps-2"
                        htmlFor="LONGER_FITNESS"
                      >
                        be confident to go on longer rides out of the
                        neighbourhood
                      </Form.Check.Label>
                    </Form.Check>

                    <Form.Check className="d-flex align-items-baseline">
                      <Form.Check.Input
                        {...register("goals")}
                        type="checkbox"
                        id="PARTICULAR_ROUTE"
                        value="PARTICULAR_ROUTE"
                        required
                      />
                      <Form.Check.Label
                        className="d-inline-block my-2 ps-2"
                        htmlFor="PARTICULAR_ROUTE"
                      >
                        planning and riding a particular route
                      </Form.Check.Label>
                    </Form.Check>
                  </fieldset>
                </Col>
              </Row>
            </fieldset>
          </>
        )}

        {user?.rider_type === "INSTRUCTOR" && (
          <>
            <fieldset className="form-group">
              <legend className="h3">Goal</legend>
              <Row>
                <Col md={12}>
                  <fieldset>
                    <p>
                      Please let us know the types of journey you want to help
                      new riders with (select as many as you like):
                    </p>
                    <Form.Group className="form-group">
                      <Form.Check
                        className="d-flex align-items-baseline"
                        id="type-1"
                      >
                        <Form.Check.Input
                          {...register("goals")}
                          id="COMPLETE_BEGINNER"
                          value="COMPLETE_BEGINNER"
                          type="checkbox"
                        />
                        <Form.Check.Label
                          className="d-inline-block my-2 ps-2"
                          htmlFor="COMPLETE_BEGINNER"
                        >
                          Complete beginners
                        </Form.Check.Label>
                      </Form.Check>
                      <Form.Check
                        className="d-flex align-items-baseline"
                        id="type-2"
                      >
                        <Form.Check.Input
                          {...register("goals")}
                          id="PARTICULAR_ROUTE"
                          value="PARTICULAR_ROUTE"
                          type="checkbox"
                        />
                        <Form.Check.Label
                          className="d-inline-block my-2 ps-2"
                          htmlFor="PARTICULAR_ROUTE"
                        >
                          Planning and riding a particular route
                        </Form.Check.Label>
                      </Form.Check>
                      <Form.Check
                        className="d-flex align-items-baseline"
                        id="type-3"
                      >
                        <Form.Check.Input
                          {...register("goals")}
                          id="LEVEL_2_OR_3"
                          value="LEVEL_2_OR_3"
                          type="checkbox"
                        />
                        <Form.Check.Label
                          className="d-inline-block my-2 ps-2"
                          htmlFor="LEVEL_2_OR_3"
                        >
                          Bikeability Level 2-3
                        </Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                  </fieldset>
                </Col>
              </Row>
            </fieldset>
          </>
        )}

        <div className="mb-3 mt-5 gap-2 d-flex flex-column flex-sm-row justify-content-end">
          <Button
            type="submit"
            variant="primary"
            name="update"
            className="action-button rounded-pill px-3"
            value="Update"
            disabled={!isValid}
          >
            Update Profile
          </Button>
        </div>
        {errors.non_field_errors && (
          <Alert variant="danger"> {errors.non_field_errors.message} </Alert>
        )}
      </form>
    </SettingsTab>
  );
};

SettingsProfile.propType = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.object.isRequired,
};

export default SettingsProfile;
