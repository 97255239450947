import React, { useEffect, useRef, useContext, useState } from "react";
import AuthContext from "../context/AuthContext";
import BuddyCard from "../components/buddies/BuddyCard";
import { useSelector } from "react-redux";
import { store } from "../Store";
import { Link, useNavigate } from "react-router-dom";
import {
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Dropdown,
} from "react-bootstrap";
import profileImageDefaultMed from "../assets/images/lcc-user-profile-default-360.png";
import profileImageDefaultLrg from "../assets/images/lcc-user-profile-default-720.png";
import useStartOrContinueChat from "../hooks/useStartOrContinueChat";
import { getChatIntent, removeChatIntent } from "../utils/chatIntent";

const FindBuddies = () => {
  const { dispatch } = store;
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { profiles, badges } = useSelector(({ buddies }) => {
    const profileIds = [];
    const dedupedProfiles = [];
    const badges = [];

    buddies.profiles.forEach((profile) => {
      if (!profileIds.includes(profile.uid)) {
        profileIds.push(profile.uid);
        dedupedProfiles.push(profile);
      }

      badges.push(
        ...(profile.badges?.reduce(
          (array, profileBadge) =>
            badges.some((badge) => badge.name === profileBadge.name)
              ? array
              : [...array, profileBadge],
          []
        ) || [])
      );
    });

    return {
      isLoading: buddies.isLoading,
      profiles: dedupedProfiles,
      badges: badges.sort((a, b) => a.name.localeCompare(b.name)),
    };
  });

  const [area, setArea] = useState(
    user.home_postcode ? "home" : user.work_postcode ? "work" : null
  );
  const [matchGender, setMatchGender] = useState(false);
  const [orderBy, setOrderBy] = useState("distance_from");

  const [selectedBadges, setSelectedBadges] = useState([]);
  const [showInstructors, setShowInstructors] = useState(false);
  const [showDbsChecked, setShowDbsChecked] = useState(false);

  const areaSelectRef = useRef();

  const matchGenderCheckboxRef = useRef();

  const getSelectedOption = (select) => {
    if (select.selectedOptions || select.selectedOptions.length > 0) {
      let value = select.selectedOptions[0].value;
      if (value !== "-1") {
        return value;
      }
    }

    return null;
  };

  const updateArea = () => {
    setArea(getSelectedOption(areaSelectRef.current));
  };

  const updateMatchGender = () => {
    setMatchGender(matchGenderCheckboxRef.current.checked);
  };

  const updateOrderBy = (e) => {
    setOrderBy(e.target.value);
  };

  const updateSelectedBadges = (value) => {
    const isBadgeSelected = selectedBadges.includes(value);

    if (isBadgeSelected) {
      const filteredBadges = selectedBadges.filter((badge) => badge !== value);

      setSelectedBadges(filteredBadges);
      return;
    }

    setSelectedBadges([...selectedBadges, value]);
  };

  const clearSelectedBadges = () => {
    setShowDbsChecked(false);
    setShowInstructors(false);
    setSelectedBadges([]);
  };

  useEffect(() => {
    let params = [];

    params.push(`area=${area}`);
    params.push(`matchGender=${matchGender}`);

    params.push(`orderBy=${orderBy}`);

    dispatch.buddies.loadData(params);
  }, [area, matchGender, orderBy]);

  const { buddyUID, chatIntentTimestamp } = getChatIntent();
  const startOrContinueChat = useStartOrContinueChat(buddyUID, user?.uid);

  useEffect(() => {
    // if user tries to get to this page and they are not onboarded redirect them to the onboarding
    if (!user?.onboarded) {
      navigate("/onboarding");
      return;
    }

    // If they have a buddyUID stored in their chat intent local storage
    // And the page is connected - this is required so make sure amity is loaded
    if (!!buddyUID) {
      // If the start or continue chat methods don't exist then return early.
      if (
        !startOrContinueChat?.continueChat ||
        !startOrContinueChat?.startChat
      ) {
        return;
      }

      const isTimeoutNotExceeded =
        Date.now() - chatIntentTimestamp <
        parseInt(window.Config.REACT_APP_LINK_TIMEOUT_MINUTES) * 60000;

      // If the timeout value isn't exceeded
      if (isTimeoutNotExceeded) {
        // Start or continue the chat with the buddy
        if (startOrContinueChat?.alreadyChatting) {
          startOrContinueChat?.continueChat();
        } else {
          startOrContinueChat?.startChat();
        }
      }

      // Remove the chat intent params from local storage
      removeChatIntent();
    }
  }, [startOrContinueChat]);

  useEffect(() => {
    // if user tries to get to this page and they are not onboarded
    if (!user?.onboarded) {
      navigate("/onboarding");
    }
  });

  const filteredProfiles = profiles.filter(
    (profile) =>
      selectedBadges.every((selectedBadge) =>
        profile.badges.some(
          (profileBadge) => selectedBadge === profileBadge.name
        )
      ) &&
      (!showDbsChecked || profile.verified) &&
      (!showInstructors || profile.rider_type === "INSTRUCTOR")
  );
  const selectedFilterLength =
    selectedBadges.length +
    (showDbsChecked ? 1 : 0) +
    (showInstructors ? 1 : 0);
  const hasFiltersSelected = !!selectedFilterLength;

  const filters = (
    <Form>
      <Row className="mb-3 g-3">
        {user.home_postcode && user.work_postcode && (
          <Col sm="auto">
            <InputGroup className="flex-grow-0 w-auto flex-shrink-0">
              <InputGroup.Text>Near</InputGroup.Text>

              <Form.Select
                aria-label="Location:"
                ref={areaSelectRef}
                defaultValue={area}
                onChange={updateArea}
              >
                <option value="home">Home</option>
                <option value="work">Work</option>
              </Form.Select>
            </InputGroup>
          </Col>
        )}

        <Col sm="auto">
          <InputGroup className="flex-grow-0 w-auto flex-shrink-0">
            <InputGroup.Text>Order by</InputGroup.Text>

            <Form.Select value={orderBy} onChange={updateOrderBy}>
              <option value="distance_from">Distance</option>
              <option value="last_seen">Last Active</option>
              <option value="date_joined">Recently Joined</option>
            </Form.Select>
          </InputGroup>
        </Col>

        {user.gender &&
          (user.gender === "MALE" || user.gender === "FEMALE") && (
            <Col sm="auto" className="ms-auto">
              <Form.Check
                type="switch"
                ref={matchGenderCheckboxRef}
                defaultChecked={matchGender}
                onClick={updateMatchGender}
                id="custom-switch"
                label="Match gender"
              />
            </Col>
          )}

        {!!badges.length && (
          <Col sm="auto" className="ms-auto">
            <Dropdown className="flex-grow-0 w-auto flex-shrink-0">
              <Dropdown.Toggle
                variant={hasFiltersSelected ? "primary" : "secondary"}
              >
                Filters ({selectedFilterLength})
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => setShowInstructors(!showInstructors)}
                  active={showInstructors}
                >
                  <span className="material-symbols-sharp">verified</span>{" "}
                  Instructor
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => setShowDbsChecked(!showDbsChecked)}
                  active={showDbsChecked}
                >
                  <span className="material-symbols-sharp">verified_user</span>{" "}
                  DBS Checked
                </Dropdown.Item>

                <Dropdown.Divider />
                {badges.map((badge) => (
                  <Dropdown.Item
                    key={badge.name}
                    active={selectedBadges.includes(badge.name)}
                    onClick={() => updateSelectedBadges(badge.name)}
                  >
                    <span className="material-symbols-sharp">{badge.icon}</span>{" "}
                    {badge.name}
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => clearSelectedBadges()}>
                  <span className="material-symbols-sharp">close</span> Clear
                  filters
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        )}
      </Row>
    </Form>
  );

  return (
    <>
      <Container fluid className="visually-hidden">
        <Row className="mb-3">
          <Col sm="12" lg="12">
            <h1>Find Buddies</h1>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row className="mb-1">{filters}</Row>
      </Container>

      <Container fluid>
        {profiles.length ? (
          <div>
            {filteredProfiles.length ? (
              <Row
                xs={2}
                md={3}
                lg={4}
                xl={5}
                xxl={6}
                className="g-3 align-items-stretch"
              >
                {filteredProfiles.map(
                  (
                    {
                      uid,
                      profile_photo_med,
                      profile_photo_lrg,
                      username,
                      rider_type,
                      location,
                      distance_from,
                      badges,
                      verified,
                      last_seen,
                    },
                    key
                  ) => {
                    return (
                      <Col key={`buddy-card-id-${key}`}>
                        <BuddyCard
                          uid={uid}
                          imgSrc={profile_photo_med || profileImageDefaultMed}
                          srcSet={[
                            profile_photo_med || profileImageDefaultMed,
                            profile_photo_lrg || profileImageDefaultLrg,
                          ]}
                          locationString={location}
                          name={username}
                          type={rider_type}
                          distanceFrom={distance_from}
                          profileBadges={badges}
                          verified={verified}
                          lastSeen={last_seen}
                        />
                      </Col>
                    );
                  }
                )}
              </Row>
            ) : (
              <div className="d-flex flex-column align-items-center justify-content-center py-5">
                <h2>Sorry, no Cycle Buddies found that match your filters</h2>
                <p>Adjust your filters to find more Cycle Buddies.</p>
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="d-flex flex-column align-items-center justify-content-center py-5">
              <h2>Sorry, no Cycle Buddies found near your location</h2>
              <p>
                <Link to="/settings">Change your location.</Link>
              </p>
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default FindBuddies;
