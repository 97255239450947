export const convertMetresToMiles = (distance) => {
  return (distance * 0.0006213712).toFixed(3);
};

export const addLngLatLocations = async (formData) => {
  const new_home_postcode = formData.home_postcode;
  const new_work_postcode = formData.work_postcode;

  const postCodeArray = [new_home_postcode, new_work_postcode];

  if (postCodeArray.length > 0) {
    try {
      const responseRaw = await fetch("https://api.postcodes.io/postcodes", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          postcodes: postCodeArray,
        }),
      });
      const response = await responseRaw.json();

      response?.result?.map(({ query, result }) => {
        if (query === new_home_postcode) {
          formData.home_lat = !!result ? result.latitude : 0;
          formData.home_lng = !!result ? result.longitude : 0;
          formData.home_location = !!result ? result.admin_ward : 0;
        }

        if (query === new_work_postcode) {
          formData.work_lat = !!result ? result.latitude : 0;
          formData.work_lng = !!result ? result.longitude : 0;
          formData.work_location = !!result ? result.admin_ward : 0;
        }
      });
      return formData;
    } catch (error) {
      console.error(error);
      throw error;
    }
  } else {
    return formData;
  }
};

//export const postCodeRegex = /^(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) [0-9][A-Z]{2}$/
export const postCodeRegex =
  /^[A-Z]{1,2}[0-9RCHNQ][0-9A-Z]?\s?[0-9][ABD-HJLNP-UW-Z]{2}$|^[A-Z]{2}-?[0-9]{4}$/i;

export const phoneNumberRegex =
  /((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}$/;
